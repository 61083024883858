@import '../../../styles/constants';

.cell-content {
  font-size: 16px;
  line-height: 24px;  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-family:  $fontFamily
}
.id-text-field {
  color: #00a3df;
  font-size: 16px;
  line-height: 24px
}
.merchandise-search-form {
  min-height: 230px
}
.container_456 {
  max-width: 456px;
  margin-right: 40px;
  margin-top: 24px;
  @media (max-width: 1024px) {
    margin-right: 16px;
    max-width: 408px;
  }
}
.container_208 {
  max-width: 208px;
  margin-right: 40px;
  margin-top: 24px;
  @media (max-width: 1024px) {
    margin-right: 0px;
  }
}