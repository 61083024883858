.price-suggestion {
  .popup-title {
    margin-top: 56px;
    text-align: center;
    .title-content{
      font-size: 40px;
      font-weight: 700
    }
  }
  .popup-content-container {
    margin: 0 80px;
  }
  .dialog-content {
    padding: 0;
    .form-row-container {
      margin: 0 80px;
    }
  }
  .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 40px;
    right: 40px;
      cursor: pointer;
  }
   
  .form-row {
    height: 96px;
    display: flex;
    justify-content: space-between
  }
  .form-row:first-child {
    margin-top: 40px
  }
  .form-row:not(:first-child) {
    margin-top: 24px
  }
  .btn-container {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    .custom-btn {
      font-size: 16px;
      font-family: 'Noto Sans JP', sans-serif;
      border: 0;
      padding: 0;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 4px;
      font-weight: bold
    }
    .back-btn {
      width: 222px;
      color: #202a6a;
      border:1px solid #202a6a
    }
    .calc-btn {
      width: 224px;
      margin-left: 16px;
      background-color: #202a6b;
      color: #ffffff;
    }
  }
  .custom-field {
    width: 280px;
    margin: 0px;
  }
  .suggesting-price-container {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: #f5f6fa;
    display: flex;
    .suggesting-price {
      color: #202a6a;
      font-weight: bold;
      font-size: 32px;
      line-height: 47px;
    }
    .suggesting-range {
      margin-left: 16px;
      color: #3c3c3c;
      .suggesting-range-text {
        font-size: 12px;
        line-height: 18px;
      }
      .suggesting-range-content {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}