@import '../../../styles/constants';

.block-field {
  background-color: #ffffff;
  margin: 20px 50px 10px 50px !important;
  padding: 0 48px 0 16px;

}

.layout {
  background-color: #f5f6fa;
}

.layout-fullwidth {
  width: 100% !important;
}

.title-container{
  margin-left: -16px
}

.title {
  display: inline-block;
  width: 100%;
  margin: 30px 0 0 42px !important;
  font-size: 20px;
  color: #3c3c3c;
  font-weight: bold;
  font-family:  $fontFamily
}

.title::after {
  content: "";
  display: block;
  background-color: #00a3df;
  height: 4px;
  width: 100%;
  margin-top: 16px;
}

.first-field {
  position: relative;
}

.first-field::after {
  content: "";
  height: 35%;
  width: 1px;
  position: absolute;
  right: 15px;
  top: 35px;
  background-color: #dddddd;
  @media (max-width: 1104px) {
   display: none
  }
}

.label-image {
  font-size: 14px;
  color: #f5bb00;
  margin-top: 8px;
  margin-bottom: 4px;
  font-family:  $fontFamily
}

.AdjustmentField {
  border-radius: 4px;
  width: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 10px;
  height: auto;
  min-height: 25px;
  margin-bottom: 20px;
  margin-top: 7px;
  background-color: #f5f6fa;
  font-size: 16px;
  border: solid 1px #dddddd;
  color: #3c3c3c;
}

.title-header {
    font-family:  $fontFamilyTitle;
    color: #3c3c3c;
    font-size: 40px;
    width: 180px;
    position: absolute;
    left: 407px;
    @media (max-width: 1024px) {
      left: 328px;
    }
}

.title-status {
  font-size: 14px;
  margin-bottom: 8px;
  color: #3c3c3c;
  font-weight: 500;
  font-family:  $fontFamily
}
.label-value {
  font-size: 14px;
  color: #f5bb00;
  margin-top: 3px;
  font-family:  $fontFamily
}
.title-field {
  font-size: 14px;
  color: #3c3c3c;
  font-weight: 500;
  font-family:  $fontFamily
}
