@import '../../styles/constants';

.Delete_Title h2{
  font-size: 40px;
  text-align: center;
  color: #3c3c3c;
  margin: 5px 0 10px 0;
  font-family: $fontFamilyTitle 
}

.Delete_Confirmed{
  font-size: 16px;
  text-align: center;
  color: #3c3c3c;
  font-family: $fontFamily
}

.Button-Field{
  justify-content:center !important;
  margin-bottom: 25px !important;
}
.Close_Dialog{
  background-image: url('../../assets/big.svg');
  background-repeat:no-repeat;
  position: relative !important;
  right: -556px;
  top:40px;
  width: 10px
}

.Close_Dialog:hover{
  background-color: unset !important;
  color: unset !important;
}

.Delete-Field div{
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px
}

@media (max-width: 1420px) {
  #TextError{
    display: none
  }
}
