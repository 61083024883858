@import '../../styles/constants';

.list-status-btn {
    border-radius: 16px;
    font-size: 14px;
    border: solid 1px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fontFamily;
    width: max-content;
    line-height: 14px;
    .status-icon {
        width: 16px;
        height: 16px;
        margin-left: 8px;
    }
    .status-text {
        margin-left: 8px;
        margin-right: 8px;
    }
}
.selling-state {
    border-color: #008dd5;
    color: #008dd5;
}
.suspended-state {
    border-color: #fe5f55;
    color: #fe5f55;
}
.negotiate-state {
    border-color: #7a306c;
    color: #7a306c;
}
.completed-state {
    border-color: #188e6b;
    color: #188e6b;
}
.process-state {
    border-color: #ffa500;
    color: #ffa500;
}
.pending-state {
  border-color: #b60081;
  color: #b60081;
}
.confirm-state {
    border-color: #ffa500;
    color: #ffa500;
}
.rejected-state {
    border-color: #9b1d20;
    color: #9b1d20;
}
.payment-state {
    border-color: #7678ed;
    color: #7678ed;
}
.reviewed-state {
    border-color: #8fc31f;
    color: #8fc31f;
}
