@import '../../../styles/constants';

.simple-form-edit {
  background-color: #f5f6fa;
  padding-left: 43px;
  padding-right: 1.5rem;
  margin-bottom: 3rem;
  &> div:first-child {
    background-color: #f5f6fa;
    margin-top: -0.5rem;
  }
}

.container {
  margin-top: 16;
  margin-bottom: 8;
}

.header {
  font-family: $fontFamilyTitle ;
  font-size: 2.5rem;
  color: #3c3c3c;
  margin-bottom: 3rem;
  margin-left: -1rem;
}
