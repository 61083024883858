.cell-content {
  font-size: 16px;
  line-height: 24px;  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-family: 'Noto Sans JP', sans-serif;
}
.id-text-field {
  color: #00a3df;
  font-size: 16px;
  line-height: 24px
}
.container_160 {
  max-width: 160px;
  margin-right: 40px;
  margin-top: 24px;
  @media (max-width: 1183px) {
    margin-right: 20px;
  }
  @media (max-width: 1024px) {
    margin-right: 10px;
  }
}
.container_472 {
  max-width: 472px;
  margin-right: 40px;
  margin-top: 24px;
  @media (max-width: 1183px) {
    margin-right: 20px;
  }
  @media (max-width: 1143px) {
    margin-right: 20px;
  }
  @media (max-width: 1024px) {
    margin-right: 0px;
    max-width: 462px;
  }
}
.transaction-search-form {
  @media screen and (max-width: 1905px) {
    min-height: 240px; 
  }
}

.card_container{
  width: 97%;
  height: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  .card_info_wrapper{
    width: calc((100% - 49px)/2);
    padding: 0 24px;
    background-color: #f5f6fa;
    height: 239px;
    .card_title{
      font-family: 'Noto Sans JP', sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #3c3c3c;
      line-height: normal
    }
    .card_info{
      display:flex;
      .image_merch{
        width: 160px;
        height: 80px;
      }
      .image-avatar{
        width: 80px;
        height: 80px;
      }
      .image_merch, .image-avatar{
        margin-right: 24px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover
        }
      }
      .card_info_detail{
        width: calc(100% - 80px - 24px);
        .name_merch{
          font-family: 'Noto Sans JP', sans-serif;
          font-size: 16px !important;
          font-weight: bold;
          color: #3c3c3c;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .card_sub_info{
          display: flex;
          margin-top: -18px;
          .card_left_info{
            margin-right: 100px;
            @media (max-width: 1775px) {
              margin-right: 30px;
            }
          }
          p {
            font-size: 14px;
            color: #3c3c3c;
            font-family: 'Noto Sans JP', sans-serif;
          }
        }
        .card_sub_col_info{
          p{
            font-size: 14px;
              color: #3c3c3c;
              font-family: 'Noto Sans JP', sans-serif;
          }
        }
      }
    }
}}

.step__divider {
  height: 80px;
  border-left: 1px dashed #dddddd;
  margin-left: 24px;
  margin-right: 24px;
}
@media screen and (max-width: 1286px) {
  .step__divider {
    display: none;
  }
}
