.custom-button {
	svg {
		display: none;
	}
}

.custom-toolbar {
    margin: 0;
    justify-content: flex-end;
    background-color: transparent !important;
    align-items: flex-start !important;
}
