$pale-grey: #f5f6fa;
$greyish-brown: #3c3c3c;
#term-of-use-page-container {
  .MuiCardContent-root-424 {
    background-color: $pale-grey;
  }
  padding: 0px 80px;
  #term-of-use-page-content {
    color: $greyish-brown;
    font-weight: normal;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    text-align: justify;

    .first-title {
      font-weight: 500;
    }

    h3 {
      font-size: 40px;
      font-family: 'Sawarabi Mincho', serif;
      font-weight: normal;
      text-align: center;
      margin-bottom: 32px;

    }

    h4 {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: var(--greyish-brown);
    }

    ol {
      padding-left: 20px;
    }
  }
}
