body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.list-page .table-container-datagrid {
  margin-top: 24px; }
  @media (max-width: 1024px) {
    .list-page .table-container-datagrid {
      width: calc(100vw - 339px); } }

.list-page .table-section {
  background-color: #fff;
  overflow: auto;
  width: calc(100vw - 435px);
  border: 1.5px solid #f5f6fa; }
  @media (max-width: 1024px) {
    .list-page .table-section {
      width: calc(100vw - 339px); } }

.list-page .table-section::-webkit-scrollbar {
  height: 8px; }

.list-page .table-section::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f6fa; }

.list-page .table-section::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #cccccc; }

.list-row:hover .id-sticky {
  background: #E8E8E8; }

.list-row:hover .checkbox-sticky {
  background: #E8E8E8; }

.list-row:hover .id-sticky-no-bulk {
  background: #E8E8E8; }

.checkbox-sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background-clip: padding-box;
  background-color: #fff; }

.clickable-row {
  cursor: pointer; }

.id-sticky {
  cursor: pointer;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  background-clip: padding-box;
  background-color: #fff;
  box-shadow: 10px 5px 15px 0px rgba(205, 205, 205, 0.5); }

.id-sticky-no-bulk {
  box-shadow: 10px 5px 15px 0px rgba(205, 205, 205, 0.5);
  cursor: pointer;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background-clip: padding-box;
  background-color: #fff; }

.list-page .table-section .unseen-cell {
  border-left: 8px solid #00a3df; }

.list-page .table-section .expanding-cell {
  background-color: #f5f6fa; }

.list-page .table-section .expand-section {
  padding: 0 !important; }

.table-name-datagrid {
  height: 57px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #f5f6fa;
  border-bottom: 0;
  color: #202a6b; }

.list-status-btn {
  border-radius: 16px;
  font-size: 14px;
  border: solid 1px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP", sans-serif;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  line-height: 14px; }
  .list-status-btn .status-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px; }
  .list-status-btn .status-text {
    margin-left: 8px;
    margin-right: 8px; }

.selling-state {
  border-color: #008dd5;
  color: #008dd5; }

.suspended-state {
  border-color: #fe5f55;
  color: #fe5f55; }

.negotiate-state {
  border-color: #7a306c;
  color: #7a306c; }

.completed-state {
  border-color: #188e6b;
  color: #188e6b; }

.process-state {
  border-color: #ffa500;
  color: #ffa500; }

.pending-state {
  border-color: #b60081;
  color: #b60081; }

.confirm-state {
  border-color: #ffa500;
  color: #ffa500; }

.rejected-state {
  border-color: #9b1d20;
  color: #9b1d20; }

.payment-state {
  border-color: #7678ed;
  color: #7678ed; }

.reviewed-state {
  border-color: #8fc31f;
  color: #8fc31f; }

.cell-content {
  font-size: 16px;
  line-height: 24px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-family: "Noto Sans JP", sans-serif; }

.id-text-field {
  color: #00a3df;
  font-size: 16px;
  line-height: 24px; }

.merchandise-search-form {
  min-height: 230px; }

.container_456 {
  max-width: 456px;
  margin-right: 40px;
  margin-top: 24px; }
  @media (max-width: 1024px) {
    .container_456 {
      margin-right: 16px;
      max-width: 408px; } }

.container_208 {
  max-width: 208px;
  margin-right: 40px;
  margin-top: 24px; }
  @media (max-width: 1024px) {
    .container_208 {
      margin-right: 0px; } }

.csv-popup .popup-title {
  margin-top: 56px;
  text-align: center; }
  .csv-popup .popup-title .title-content {
    font-size: 40px;
    font-weight: 700; }

.csv-popup .popup-content-container {
  margin: 0 80px; }

.csv-popup .dialog-content {
  padding: 40px 80px; }
  .csv-popup .dialog-content .error-file {
    border: solid 1px #9b1d20;
    background-color: #fff3f3; }
  .csv-popup .dialog-content .valid-file {
    background-color: #f5f6fa;
    border: dashed 1px #202a6a; }
  .csv-popup .dialog-content .csv-drop-field {
    width: 100%;
    padding: 24px 0px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .csv-popup .dialog-content .csv-drop-field:focus {
      outline: none; }
    .csv-popup .dialog-content .csv-drop-field img {
      margin-bottom: 8px;
      cursor: pointer; }
    .csv-popup .dialog-content .csv-drop-field .size-info-div {
      color: #aaaaaa;
      font-family: 'Noto Sans JP', sans-serif;
      font-size: 12px; }
    .csv-popup .dialog-content .csv-drop-field .error-content {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .csv-popup .dialog-content .csv-drop-field .error-content .error-icon {
        margin-bottom: 0;
        margin-right: 4px; }
      .csv-popup .dialog-content .csv-drop-field .error-content .error-text {
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 14px;
        color: #9b1d20;
        line-height: 20px; }
    .csv-popup .dialog-content .csv-drop-field .file-name-display {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      .csv-popup .dialog-content .csv-drop-field .file-name-display img {
        margin-bottom: 0px; }
      .csv-popup .dialog-content .csv-drop-field .file-name-display div {
        font-family: 'Noto Sans JP', sans-serif;
        color: #3c3c3c;
        font-size: 14px; }
  .csv-popup .dialog-content .instruction-csv-container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Noto Sans JP', sans-serif;
    margin-bottom: 40px;
    color: #3c3c3c; }
    .csv-popup .dialog-content .instruction-csv-container .instruction-line {
      font-size: 14px; }
    .csv-popup .dialog-content .instruction-csv-container .instruction-btn-container {
      display: flex;
      margin-top: 16px; }
    .csv-popup .dialog-content .instruction-csv-container .instruction-link {
      font-size: 12px;
      margin-bottom: 16px; }
      .csv-popup .dialog-content .instruction-csv-container .instruction-link a {
        color: #00a3df; }
    .csv-popup .dialog-content .instruction-csv-container .download-btn-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px; }

.csv-popup .close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer; }

.csv-popup .btn-container {
  display: flex;
  justify-content: center; }
  .csv-popup .btn-container .custom-btn {
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 4px;
    font-weight: bold; }
  .csv-popup .btn-container .back-btn {
    width: 222px;
    color: #202a6a;
    border: 1px solid #202a6a; }
  .csv-popup .btn-container .calc-btn {
    width: 224px;
    margin-left: 16px;
    background-color: #202a6b;
    color: #ffffff; }

.Delete_Title h2 {
  font-size: 40px;
  text-align: center;
  color: #3c3c3c;
  margin: 5px 0 10px 0;
  font-family: "Sawarabi Mincho", serif; }

.Delete_Confirmed {
  font-size: 16px;
  text-align: center;
  color: #3c3c3c;
  font-family: "Noto Sans JP", sans-serif; }

.Button-Field {
  justify-content: center !important;
  margin-bottom: 25px !important; }

.Close_Dialog {
  background-image: url(/static/media/big.0a6140cf.svg);
  background-repeat: no-repeat;
  position: relative !important;
  right: -556px;
  top: 40px;
  width: 10px; }

.Close_Dialog:hover {
  background-color: unset !important;
  color: unset !important; }

.Delete-Field div {
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px; }

@media (max-width: 1420px) {
  #TextError {
    display: none; } }

.block-field {
  background-color: #ffffff;
  margin: 20px 50px 10px 50px !important;
  padding: 0 48px 0 16px; }

.layout {
  background-color: #f5f6fa; }

.layout-fullwidth {
  width: 100% !important; }

.title-container {
  margin-left: -16px; }

.title {
  display: inline-block;
  width: 100%;
  margin: 30px 0 0 42px !important;
  font-size: 20px;
  color: #3c3c3c;
  font-weight: bold;
  font-family: "Noto Sans JP", sans-serif; }

.title::after {
  content: "";
  display: block;
  background-color: #00a3df;
  height: 4px;
  width: 100%;
  margin-top: 16px; }

.first-field {
  position: relative; }

.first-field::after {
  content: "";
  height: 35%;
  width: 1px;
  position: absolute;
  right: 15px;
  top: 35px;
  background-color: #dddddd; }
  @media (max-width: 1104px) {
    .first-field::after {
      display: none; } }

.label-image {
  font-size: 14px;
  color: #f5bb00;
  margin-top: 8px;
  margin-bottom: 4px;
  font-family: "Noto Sans JP", sans-serif; }

.AdjustmentField {
  border-radius: 4px;
  width: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 10px;
  height: auto;
  min-height: 25px;
  margin-bottom: 20px;
  margin-top: 7px;
  background-color: #f5f6fa;
  font-size: 16px;
  border: solid 1px #dddddd;
  color: #3c3c3c; }

.title-header {
  font-family: "Sawarabi Mincho", serif;
  color: #3c3c3c;
  font-size: 40px;
  width: 180px;
  position: absolute;
  left: 407px; }
  @media (max-width: 1024px) {
    .title-header {
      left: 328px; } }

.title-status {
  font-size: 14px;
  margin-bottom: 8px;
  color: #3c3c3c;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif; }

.label-value {
  font-size: 14px;
  color: #f5bb00;
  margin-top: 3px;
  font-family: "Noto Sans JP", sans-serif; }

.title-field {
  font-size: 14px;
  color: #3c3c3c;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif; }

.simple-form-edit {
  background-color: #f5f6fa;
  padding-left: 43px;
  padding-right: 1.5rem;
  margin-bottom: 3rem; }
  .simple-form-edit > div:first-child {
    background-color: #f5f6fa;
    margin-top: -0.5rem; }

.container {
  margin-top: 16;
  margin-bottom: 8; }

.header {
  font-family: "Sawarabi Mincho", serif;
  font-size: 2.5rem;
  color: #3c3c3c;
  margin-bottom: 3rem;
  margin-left: -1rem; }

.custom-button svg {
  display: none; }

.custom-toolbar {
  margin: 0;
  justify-content: flex-end;
  background-color: transparent !important;
  align-items: flex-start !important; }

.text-field {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap; }

.label-text-field {
  height: 20px;
  font-family: 'Noto San JP', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3c; }

.content-text-field {
  height: 24px;
  font-family: 'Noto San JP', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3c;
  margin-top: 8px; }

.price-suggestion .popup-title {
  margin-top: 56px;
  text-align: center; }
  .price-suggestion .popup-title .title-content {
    font-size: 40px;
    font-weight: 700; }

.price-suggestion .popup-content-container {
  margin: 0 80px; }

.price-suggestion .dialog-content {
  padding: 0; }
  .price-suggestion .dialog-content .form-row-container {
    margin: 0 80px; }

.price-suggestion .close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer; }

.price-suggestion .form-row {
  height: 96px;
  display: flex;
  justify-content: space-between; }

.price-suggestion .form-row:first-child {
  margin-top: 40px; }

.price-suggestion .form-row:not(:first-child) {
  margin-top: 24px; }

.price-suggestion .btn-container {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center; }
  .price-suggestion .btn-container .custom-btn {
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 4px;
    font-weight: bold; }
  .price-suggestion .btn-container .back-btn {
    width: 222px;
    color: #202a6a;
    border: 1px solid #202a6a; }
  .price-suggestion .btn-container .calc-btn {
    width: 224px;
    margin-left: 16px;
    background-color: #202a6b;
    color: #ffffff; }

.price-suggestion .custom-field {
  width: 280px;
  margin: 0px; }

.price-suggestion .suggesting-price-container {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #f5f6fa;
  display: flex; }
  .price-suggestion .suggesting-price-container .suggesting-price {
    color: #202a6a;
    font-weight: bold;
    font-size: 32px;
    line-height: 47px; }
  .price-suggestion .suggesting-price-container .suggesting-range {
    margin-left: 16px;
    color: #3c3c3c; }
    .price-suggestion .suggesting-price-container .suggesting-range .suggesting-range-text {
      font-size: 12px;
      line-height: 18px; }
    .price-suggestion .suggesting-price-container .suggesting-range .suggesting-range-content {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px; }

.simple-form-create {
  background-color: #f5f6fa;
  padding-left: 43px;
  padding-right: 1.5rem;
  margin-bottom: 3rem; }
  .simple-form-create > div:first-child {
    background-color: #f5f6fa;
    margin-top: -0.5rem; }

.container {
  margin-top: 16;
  margin-bottom: 8; }

.header {
  font-family: "Sawarabi Mincho", serif;
  font-size: 2.5rem;
  color: #3c3c3c;
  margin-bottom: 3rem;
  margin-left: -1rem; }

.cell-content {
  font-size: 16px;
  line-height: 24px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-family: 'Noto Sans JP', sans-serif; }

.id-text-field {
  color: #00a3df;
  font-size: 16px;
  line-height: 24px; }

.container_160 {
  max-width: 160px;
  margin-right: 40px;
  margin-top: 24px; }
  @media (max-width: 1183px) {
    .container_160 {
      margin-right: 20px; } }
  @media (max-width: 1024px) {
    .container_160 {
      margin-right: 10px; } }

.container_472 {
  max-width: 472px;
  margin-right: 40px;
  margin-top: 24px; }
  @media (max-width: 1183px) {
    .container_472 {
      margin-right: 20px; } }
  @media (max-width: 1143px) {
    .container_472 {
      margin-right: 20px; } }
  @media (max-width: 1024px) {
    .container_472 {
      margin-right: 0px;
      max-width: 462px; } }

@media screen and (max-width: 1905px) {
  .transaction-search-form {
    min-height: 240px; } }

.card_container {
  width: 97%;
  height: 100%;
  padding: 24px;
  display: flex;
  align-items: center; }
  .card_container .card_info_wrapper {
    width: calc((100% - 49px)/2);
    padding: 0 24px;
    background-color: #f5f6fa;
    height: 239px; }
    .card_container .card_info_wrapper .card_title {
      font-family: 'Noto Sans JP', sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #3c3c3c;
      line-height: normal; }
    .card_container .card_info_wrapper .card_info {
      display: flex; }
      .card_container .card_info_wrapper .card_info .image_merch {
        width: 160px;
        height: 80px; }
      .card_container .card_info_wrapper .card_info .image-avatar {
        width: 80px;
        height: 80px; }
      .card_container .card_info_wrapper .card_info .image_merch, .card_container .card_info_wrapper .card_info .image-avatar {
        margin-right: 24px; }
        .card_container .card_info_wrapper .card_info .image_merch img, .card_container .card_info_wrapper .card_info .image-avatar img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .card_container .card_info_wrapper .card_info .card_info_detail {
        width: calc(100% - 80px - 24px); }
        .card_container .card_info_wrapper .card_info .card_info_detail .name_merch {
          font-family: 'Noto Sans JP', sans-serif;
          font-size: 16px !important;
          font-weight: bold;
          color: #3c3c3c;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical; }
        .card_container .card_info_wrapper .card_info .card_info_detail .card_sub_info {
          display: flex;
          margin-top: -18px; }
          .card_container .card_info_wrapper .card_info .card_info_detail .card_sub_info .card_left_info {
            margin-right: 100px; }
            @media (max-width: 1775px) {
              .card_container .card_info_wrapper .card_info .card_info_detail .card_sub_info .card_left_info {
                margin-right: 30px; } }
          .card_container .card_info_wrapper .card_info .card_info_detail .card_sub_info p {
            font-size: 14px;
            color: #3c3c3c;
            font-family: 'Noto Sans JP', sans-serif; }
        .card_container .card_info_wrapper .card_info .card_info_detail .card_sub_col_info p {
          font-size: 14px;
          color: #3c3c3c;
          font-family: 'Noto Sans JP', sans-serif; }

.step__divider {
  height: 80px;
  border-left: 1px dashed #dddddd;
  margin-left: 24px;
  margin-right: 24px; }

@media screen and (max-width: 1286px) {
  .step__divider {
    display: none; } }

.cell-content {
  font-size: 16px;
  line-height: 24px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-family: "Noto Sans JP", sans-serif; }

.id-text-field {
  color: #00a3df;
  font-size: 16px;
  line-height: 24px; }

.usermanagement-table {
  width: 900px; }

#term-of-use-page-container {
  padding: 0px 80px; }
  #term-of-use-page-container .MuiCardContent-root-424 {
    background-color: #f5f6fa; }
  #term-of-use-page-container #term-of-use-page-content {
    color: #3c3c3c;
    font-weight: normal;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    text-align: justify; }
    #term-of-use-page-container #term-of-use-page-content .first-title {
      font-weight: 500; }
    #term-of-use-page-container #term-of-use-page-content h3 {
      font-size: 40px;
      font-family: 'Sawarabi Mincho', serif;
      font-weight: normal;
      text-align: center;
      margin-bottom: 32px; }
    #term-of-use-page-container #term-of-use-page-content h4 {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: var(--greyish-brown); }
    #term-of-use-page-container #term-of-use-page-content ol {
      padding-left: 20px; }

#privacy-policy-page-container {
  padding: 0px 80px; }
  #privacy-policy-page-container .MuiCardContent-root-424 {
    background-color: #f5f6fa; }
  #privacy-policy-page-container #privacy-policy-page-content {
    color: #3c3c3c;
    line-height: 1.5;
    font-weight: normal;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    text-align: justify; }
    #privacy-policy-page-container #privacy-policy-page-content .first-title {
      font-weight: 500; }
    #privacy-policy-page-container #privacy-policy-page-content h3 {
      font-size: 40px;
      font-family: 'Sawarabi Mincho', serif;
      font-weight: normal;
      text-align: center;
      margin-bottom: 32px; }
    #privacy-policy-page-container #privacy-policy-page-content ol {
      padding-left: 20px; }

