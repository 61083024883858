.text-field {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
}

.label-text-field {
  height: 20px;
  font-family: 'Noto San JP', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3c;
}

.content-text-field {
  height: 24px;
  font-family: 'Noto San JP', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3c;
  margin-top: 8px;
}