@import '../../../styles/constants';

.cell-content {
  font-size: 16px;
  line-height: 24px;  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-family:  $fontFamily
}
.id-text-field {
  color: #00a3df;
  font-size: 16px;
  line-height: 24px
}
