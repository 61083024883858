.csv-popup {
  .popup-title {
    margin-top: 56px;
    text-align: center;
    .title-content{
      font-size: 40px;
      font-weight: 700
    }
  }
  .popup-content-container {
    margin: 0 80px;
  }
  .dialog-content {
    padding: 40px 80px;
    .error-file {
      border: solid 1px #9b1d20;
      background-color: #fff3f3;
    }
    .valid-file {
      background-color: #f5f6fa;
      border: dashed 1px #202a6a;
    }
    .csv-drop-field {
      &:focus {
        outline: none
      }
      width: 100%;
      padding: 24px 0px;
      margin-bottom: 24px;
      
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        margin-bottom: 8px;
        cursor: pointer;
      }
      .size-info-div {
        color: #aaaaaa;
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 12px;

      }
      .error-content {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        .error-icon {
          margin-bottom: 0;
          margin-right: 4px
        }
        .error-text {
          font-family: 'Noto Sans JP', sans-serif;
          font-size: 14px;
          color: #9b1d20;
          line-height: 20px
        }
      }
      .file-name-display {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
          margin-bottom: 0px;
        }
        div {
          font-family: 'Noto Sans JP', sans-serif;
          color: #3c3c3c;
          font-size: 14px;
        }
      }
    }

    .instruction-csv-container {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: 'Noto Sans JP', sans-serif;
      margin-bottom: 40px;
      color: #3c3c3c;
      .instruction-line {
        font-size: 14px;
      }
      .instruction-btn-container {
        display: flex;
        margin-top: 16px;
      }
      .instruction-link {
        font-size: 12px;
        margin-bottom: 16px;
        a {
          color: #00a3df;
        }
      }
      .download-btn-icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
  .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 40px;
    right: 40px;
      cursor: pointer;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    .custom-btn {
      font-size: 16px;
      font-family: 'Noto Sans JP', sans-serif;
      border: 0;
      padding: 0;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 4px;
      font-weight: bold
    }
    .back-btn {
      width: 222px;
      color: #202a6a;
      border:1px solid #202a6a
    }
    .calc-btn {
      width: 224px;
      margin-left: 16px;
      background-color: #202a6b;
      color: #ffffff;
    }
  }
}