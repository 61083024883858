.list-page .table-container-datagrid {
  margin-top: 24px;
  @media (max-width: 1024px) {
    width: calc(100vw - 339px);
  }
}
.list-page .table-section {
  background-color: #fff;
  overflow: auto;
  width: calc(100vw - 435px);
  border: 1.5px solid #f5f6fa;
  @media (max-width: 1024px) {
    width: calc(100vw - 339px);
  }
}
.list-page .table-section::-webkit-scrollbar {
  height: 8px;
}
.list-page .table-section::-webkit-scrollbar-track
{
  border-radius: 5px;
  background-color: #f5f6fa;
}
.list-page .table-section::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  background-color: #cccccc;
}
.list-row:hover .id-sticky{
  background: #E8E8E8;

}
.list-row:hover .checkbox-sticky{
  background: #E8E8E8;
}
.list-row:hover .id-sticky-no-bulk{
  background: #E8E8E8;
}
.checkbox-sticky {
  position: sticky;
  left: 0;
  z-index: 2;
  background-clip: padding-box;
  background-color: #fff;

}
.clickable-row {
  cursor: pointer;
}
.id-sticky {
  cursor: pointer;
  position: sticky;
  z-index: 2;
  background-clip: padding-box;
  background-color: #fff;
  box-shadow: 10px 5px 15px 0px rgba(205, 205, 205, 0.5);
}
.id-sticky-no-bulk {
  box-shadow: 10px 5px 15px 0px rgba(205, 205, 205, 0.5);
  cursor: pointer;
  position: sticky;
  left: 0;
  z-index: 2;
  background-clip: padding-box;
  background-color: #fff;
}
.list-page .table-section .unseen-cell {
  border-left: 8px solid #00a3df;
}
.list-page .table-section .expanding-cell {
  background-color: #f5f6fa
}
.list-page .table-section .expand-section {
  padding: 0 !important
}
.table-name-datagrid {
  height: 57px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #f5f6fa;
  border-bottom: 0;
  color: #202a6b;
}
