//Define Color
$pale-grey: #f5f6fa;
$greyish-brown: #3c3c3c;
#privacy-policy-page-container {
  .MuiCardContent-root-424 {
    background-color: $pale-grey;
  }
  padding: 0px 80px;
  #privacy-policy-page-content {
    color: $greyish-brown;
    line-height: 1.5;
    font-weight: normal;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    text-align: justify;

    .first-title {
      font-weight: 500;
    }
    h3 {
      font-size: 40px;
      font-family: 'Sawarabi Mincho', serif;
      font-weight: normal;
      text-align: center;
      margin-bottom: 32px;
    }
    ol {
      padding-left: 20px;
    }
  }
}
